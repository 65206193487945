const paramEntries = (new URLSearchParams(location.search)).entries();
const urlParams = Object.fromEntries(paramEntries);
function getField(name) {
	let elements = document.querySelectorAll(`[name="${name}"]`)
	return elements;
}
for (const param of paramEntries) {
	const elements = getField(param[0]);
	console.log(param,elements);
	for (const element of elements) {
		console.log(element, paramEntries);
		if(element.type=="radio") {
			element.checked = element.value === param[1];
		} else {
			element.value = param[1]
			element.checked = !!param[1]
		}
	}
}



function updateUrlFromField(inputElement) {
	//if(inputElement.type=="radio") return;
	if (inputElement.hasAttribute('name')) {
		const fieldName = inputElement.getAttribute('name');
		const fieldValue = inputElement.type=="checkbox" ? inputElement.checked :inputElement.value;

		const currentUrl = new URL(window.location.href);
		if(!fieldValue) currentUrl.searchParams.delete(fieldName)
		else currentUrl.searchParams.set(fieldName, fieldValue);
		window.history.replaceState({}, document.title, currentUrl);
	}
}

document.addEventListener('input', function (event) {

	updateUrlFromField(event.target)
});
